import React from 'react';
import { Pane, Spinner, majorScale, toaster } from 'evergreen-ui';
import QuickInfoInput from './QuickInfoInput';

export default class ApplicationQuickInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      quickInfo: null,
    };
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    const response = await fetch(`/bonds/api/applications/${urlParams.get('applicationid')}/quickinfo`);
    if (response.ok) {
      const info = await response.json();
      console.log('info:', info);

      this.setState({ loading: false, quickInfo: info });
    } else if (response.status === 404) {
      this.setState({ loading: false, quickInfo: null });
    } else {
      console.error(response.text);
    }
  }

  inputChanged = async (label, field, value) => {
    toaster.notify(`Saving ${label}...`, {
      duration: 2,
    });

    try {
      const urlParams = new URLSearchParams(window.location.search);

      const payload = {
        field: field,
        value: value,
      };

      console.log('payload: ', payload);

      const response = await fetch(`/bonds/api/applications/${urlParams.get('applicationid')}/quickinfo`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: new Headers({ 'content-type': 'application/json' }),
      });

      const info = await response.json();
      console.log('info: ', info);

      this.setState({ quickInfo: info });

      toaster.success(`Sucessfully saved ${label}.`);
    } catch (error) {
      console.error(error);

      if (error.response.status === 422) {
        toaster.danger(`Error: ${error.response.data.join('. ')}`);
      } else {
        toaster.danger(`Error saving ${label}.`);
      }
    }
  };

  render() {
    let content = (
      <Pane display="flex" alignItems="center" justifyContent="center" height={majorScale(25)}>
        <Spinner />
      </Pane>
    );

    if (!this.state.loading) {
      content = (
        <Pane marginBottom={majorScale(1)}>
          <Pane display="flex">
            <QuickInfoInput
              width="20%"
              label="Credit Score"
              marginRight={majorScale(2)}
              field="creditScore"
              onChange={this.inputChanged}
              value={this.state.quickInfo?.creditScore}
            />
            <QuickInfoInput
              width="20%"
              label="Last Credit Score"
              marginRight={majorScale(2)}
              field="creditScoreLastRun"
              type="date"
              onChange={this.inputChanged}
              value={this.state.quickInfo?.creditScoreLastRun}
            />
            <QuickInfoInput
              width="20%"
              label="Financials Received"
              marginRight={majorScale(2)}
              field="dateFinancialsReceived"
              type="date"
              onChange={this.inputChanged}
              value={this.state.quickInfo?.dateFinancialsReceived}
            />
            <QuickInfoInput
              width="20%"
              label="Fiscal Year End"
              marginRight={majorScale(2)}
              field="fiscalYearEnd"
              type="date"
              onChange={this.inputChanged}
              value={this.state.quickInfo?.fiscalYearEnd}
            />
            <QuickInfoInput
              width="20%"
              label="Claims?"
              marginRight={majorScale(2)}
              type="checkbox"
              field="claims"
              onChange={this.inputChanged}
              value={this.state.quickInfo?.claimsOrIrls}
            />
          </Pane>
          <Pane marginBottom={majorScale(1)}>
            <QuickInfoInput width="100%" label="Change of Ownership" field="changeOfOwnership" onChange={this.inputChanged} value={this.state.quickInfo?.changeOfOwnership} />
          </Pane>
          <Pane>
            <QuickInfoInput width="100%" label="Special UW Notes" type="textarea" field="specialNotes" onChange={this.inputChanged} value={this.state.quickInfo?.specialNotes} />
          </Pane>
        </Pane>
      );
    }

    return content;
  }
}
