import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

import useFetch from '../../services/useFetch';

export default function AchPaymentWarningModal() {
  const [showModal, setShowModal] = useState(true);
  const { data: achFlatFee, loading, error } = useFetch('/bonds/api/configuration/achflatfee');

  if (error) throw error;

  const content = loading ? (
    <center>
      <ProgressSpinner style={{ width: '50px', height: '50px' }} />
    </center>
  ) : (
    <>
      <p>
        Issuance of Bond when payment made via ACH may take up to 10 days due to verification from your financial institution that funds were received. Please note ACH payments are
        subject to a ${parseFloat(achFlatFee).toFixed(2)} fee.
      </p>
      <div style={{ textAlign: 'center' }}>
        <Button label="I Accept" icon="pi pi-check" onClick={() => setShowModal(false)} />
      </div>
    </>
  );

  return (
    <Dialog header="ACH Information" visible={showModal} style={{ width: '50vw' }} modal={true} resizable={false} draggable={false} closeOnEscape={false} closable={false}>
      {content}
    </Dialog>
  );
}
