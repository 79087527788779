import React, { useState, useEffect } from 'react';

import { Sidebar } from 'primereact/sidebar';

import InfoSidebarContent from './InfoSidebarContent.js';

import './InfoSidebar.scss';

InfoSidebar.propTypes = {};

const ISCOLLAPSED_LOCALSTORAGE_KEY = 'infosidebar-iscollapsed';

export default function InfoSidebar() {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem(ISCOLLAPSED_LOCALSTORAGE_KEY)) ?? false;
    } catch {
      console.error(`${ISCOLLAPSED_LOCALSTORAGE_KEY} could not be parsed into JSON.`);
      return true;
    }
  });

  useEffect(() => localStorage.setItem(ISCOLLAPSED_LOCALSTORAGE_KEY, JSON.stringify(isCollapsed)), [isCollapsed]);

  function icons() {
    return isCollapsed ? (
      <button className="p-sidebar-close p-link" type="button" onClick={() => setIsCollapsed(false)}>
        <span className="p-sidebar-close-icon pi pi-angle-double-right" />
      </button>
    ) : (
      <button className="p-sidebar-close p-link" type="button" onClick={() => setIsCollapsed(true)}>
        <span className="p-sidebar-close-icon pi pi-angle-double-left" />
      </button>
    );
  }

  return (
    <Sidebar
      visible={true}
      modal={false}
      position="left"
      className={'info-sidebar ' + (isCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded')}
      dismissable={false}
      showCloseIcon={false}
      closeOnEscape={false}
      onHide={() => {}}
      icons={icons}
    >
      {isCollapsed ? (
        <div className="closed-title" onClick={() => setIsCollapsed(false)}>
          View Info
        </div>
      ) : (
        <InfoSidebarContent />
      )}
    </Sidebar>
  );
}
