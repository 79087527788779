import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';

export default function FraudWarningModal() {
  
  const [showModal, setShowModal] = useState(false);
  var fraudStatementHtm = require('./FraudStatement.htm');
 
  return (
    <>
      
      By checking this box, I have read and agree to the Fraud Warning. 
      I certify that I am a principal, partner or officer of the Applicant who
      is duly authorized to execute this Application on behalf of and as binding on the Applicant.
      
      <p><a pButton style={{cursor: 'pointer'}} onClick={() => setShowModal(true)}>View Fraud Statement</a></p>
      
      <Dialog header="Fraud Warning" visible={showModal} style={{ width: '50vw' }} onHide={() => setShowModal(false)} modal={true} resizable={false} draggable={false} closeOnEscape={true} closable={true}>
      
      <iframe src={fraudStatementHtm } style={{width: '-webkit-fill-available', height: '75vh'}}></iframe>
      
      </Dialog>
    </>
  );
}
