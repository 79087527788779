import React, { useState, useRef, useEffect } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import moment from 'moment';

import './Notifications.scss';

export default function Notifications() {
  const statusFilters = [
    { label: 'Pending', value: 0 },
    { label: 'In Process', value: 1 },
    { label: 'Done', value: 2 },
    { label: 'Error', value: 3 },
  ];
  const defaultLazyParams = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'createdOn',
    sortOrder: -1,
  };
  const [showLoading, setShowLoading] = useState(true);
  const [lazyParams, setLazyParams] = useState(defaultLazyParams);
  const [totalRecords, setTotalRecords] = useState(0);
  const [notifications, setNotifications] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [currentErrorCount, setCurrentErrorCount] = useState(0);
  const [currentProcessingCount, setCurrentProcessingCount] = useState(0);

  const op = useRef(null);
  const filterOp = useRef(null);

  useEffect(() => {
    async function load() {
      await lazyLoadData();
    }

    load();
  }, [lazyParams, statusFilter]);

  const lazyLoadData = async function () {
    setShowLoading(true);

    const response = await fetch(
      `/bonds/api/usernotifications?rowCount=${lazyParams.rows}&skip=${lazyParams.first}&sortAscending=${lazyParams.sortOrder === 1}&sortField=${
        lazyParams.sortField
      }&statusFilter=${statusFilter}`,
    );
    if (response.ok) {
      const payload = await response.json();
      setNotifications(payload.userNotifications);
      setTotalRecords(payload.totalCount);
      setCurrentErrorCount(payload.totalErrorCount);
      setCurrentProcessingCount(payload.totalProcessingCount);
      setShowLoading(false);
    } else {
      console.error(response.text);
    }
  };

  const onPage = function (event) {
    setLazyParams(event);
  };

  const onSort = function (event) {
    setLazyParams(event);
  };

  const openNotification = function (e) {
    setLazyParams(defaultLazyParams);
    setStatusFilter();
    op.current.toggle(e);
  };

  const toggleFilter = function (e) {
    filterOp.current.toggle(e);
  };

  const closeFilter = function (e) {
    filterOp.current.hide();
  };

  const dateColumnTemplate = function (rowData) {
    return `${moment(rowData.createdOn).format('l')} ${moment(rowData.createdOn).format('LT')}`;
  };

  const statusColumnTemplate = function (rowData) {
    switch (rowData.userNotificationStatus) {
      case 0:
        return <i className="pi pi-clock working-notification" title="Pending"></i>;
      case 1:
        return <i className="pi pi-spin pi-spinner working-notification" title="In Process"></i>;
      case 2:
        return <i className="pi pi-check done-notification" title="Done"></i>;
      case 3:
        return <i className="pi pi-exclamation-circle error-notification" title="Error"></i>;
      default:
        return rowData.userNotificationStatus;
    }
  };

  const filterButtonIcon = statusFilter != null && statusFilter >= 0 ? 'pi pi-filter-slash' : 'pi pi-filter';

  const header = (
    <div className="table-header">
      Notifications
      <Button icon={filterButtonIcon} onClick={toggleFilter} />
      <OverlayPanel ref={filterOp}>
        <ListBox
          value={statusFilter}
          options={statusFilters}
          onChange={(e) => {
            setStatusFilter(e.value);
            closeFilter();
          }}
        />
        <Button label="Close" onClick={closeFilter} className="filter-close-button" />
      </OverlayPanel>
    </div>
  );

  let badge = <></>;
  if (currentErrorCount > 0) {
    badge = <Badge value={currentErrorCount} severity="danger" className="notification-badge"></Badge>;
  } else if (currentProcessingCount > 0) {
    badge = <Badge value={currentProcessingCount} className="notification-badge"></Badge>;
  }

  return (
    <>
      <Button type="button" icon="pi pi-bars" onClick={openNotification} aria-haspopup aria-controls="overlay_panel" className="p-button-text p-button-plain open-notifications">
        {badge}
      </Button>

      <OverlayPanel ref={op} id="overlay_panel" style={{ width: '450px' }}>
        <DataTable
          value={notifications}
          lazy
          paginator
          first={lazyParams.first}
          rows={10}
          loading={showLoading}
          totalRecords={totalRecords}
          onPage={onPage}
          onSort={onSort}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          emptyMessage="No notifications found"
          header={header}
        >
          <Column field="createdOn" header="Date" sortable body={dateColumnTemplate} />
          <Column field="userNotificationStatus" header="Status" sortable body={statusColumnTemplate} className="status-column" />
        </DataTable>
      </OverlayPanel>
    </>
  );
}
