import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

SaveNotification.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default function SaveNotification({ show, onHide }) {
  useEffect(() => {
    const timer = setTimeout(onHide, 3000);
    return () => clearTimeout(timer);
  }, [show, onHide]);

  if (!show) return null;
  return (
    <span style={{ paddingLeft: '1em' }}>
      <i className="pi pi-check" style={{ color: 'green' }} />
      Saved
    </span>
  );
}
