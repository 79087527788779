import React from 'react';
import { Pane, Label, TextInput, Textarea, Checkbox, majorScale } from 'evergreen-ui';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';

class QuickInfoInput extends React.Component {
  constructor(props) {
    super(props);

    this.id = _.uniqueId('quick-info-input-');

    this.state = {
      snapshot: this.props.value || '',
    };
  }

  componentDidMount() {}

  textChanged = (event) => {
    const newValue = event.target.value;

    if (this.state.snapshot !== newValue) {
      this.setState({ snapshot: newValue });
      this.reportChange(this.props.label, this.props.field, newValue);
    }
  };

  checkChanged = (event) => {
    const newValue = event.target.checked;

    if (this.state.snapshot !== newValue) {
      this.setState({ snapshot: newValue });
      this.reportChange(this.props.label, this.props.field, newValue);
    }
  };

  dateChanged = (date) => {
    const newValue = date.value;

    if (this.state.snapshot !== newValue) {
      this.setState({ snapshot: newValue });
      this.reportChange(this.props.label, this.props.field, newValue);
    }
  };

  reportChange(label, field, value) {
    this.props.onChange(label, field, value);
  }

  renderCheckbox() {
    //marginLeft usage is a hack. Could not get checkbox centered in container.
    return <Checkbox id={this.id} width="100%" marginLeft={majorScale(3)} onChange={this.checkChanged} checked={this.props.value} />;
  }

  renderTextArea() {
    return <Textarea id={this.id} width="100%" onBlur={this.textChanged} defaultValue={this.props.value} />;
  }

  renderString() {
    return <TextInput id={this.id} width="100%" onBlur={this.textChanged} defaultValue={this.props.value} />;
  }

  renderDate() {
    console.log('value: ', this.props.value);

    return (
      <Calendar value={this.formatDate(this.props.value)} onChange={this.dateChanged}></Calendar>
      // <DatePicker
      //   selected={this.formatDate(this.props.value)}
      //   onChange={this.dateChanged}
      //   customInput={<TextInput id={this.id} width="100%" />}
      // />
    );
  }

  formatDate(input) {
    if (!input) {
      return '';
    }

    return moment(input).toDate();
  }

  render() {
    let content = null;
    switch ((this.props.type || '').toLowerCase()) {
      case 'textarea':
        content = this.renderTextArea();
        break;
      case 'checkbox':
        content = this.renderCheckbox();
        break;
      case 'date':
        content = this.renderDate();
        break;
      default:
        content = this.renderString();
        break;
    }

    const marginRight = this.props.marginRight || '';

    return (
      <Pane alignItems="center" width={this.props.width} marginRight={marginRight}>
        <Label htmlFor={this.id} display="block" marginBottom={4}>
          {this.props.label}
        </Label>
        {content}
      </Pane>
    );
  }
}

QuickInfoInput.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string.isRequired,
  marginRight: PropTypes.number,
  type: PropTypes.string,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default QuickInfoInput;
