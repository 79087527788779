import React, { useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';

import SaveNotification from './SaveNotification.js';
import useFetchMany from '../../services/useFetchMany';
import ChooseDialog from './broker/ChooseDialog.js';

import './InfoSidebarContent.scss';

InfoSidebarContent.propTypes = {};

const urlParams = new URLSearchParams(window.location.search);

export default function InfoSidebarContent() {
  const [successMessages, setSuccessMessages] = useState({});
  const [showChooseBrokerDialog, setShowChooseBrokerDialog] = useState(false);

  const invoiceTermsSelectItems = [
    { label: 'Gross', value: 1 },
    { label: 'Net', value: 0 },
  ];

  const { results, loading, error, setState } = useFetchMany({
    accountManagers: { url: '/bonds/api/employees/bondlink-account-managers' },
    producers: { url: '/bonds/api/employees/bondlink-producers' },
    integrationInfo: { url: `/bonds/api/applications/${urlParams.get('applicationid')}/applicationintegrationinfo/`, validErrorCodes: [404] },
    legalEntities: { url: '/bonds/api/legalentities/' },
  });

  async function changed(fieldName, value) {
    const response = await fetch(`/bonds/api/applications/${urlParams.get('applicationid')}/ApplicationIntegrationInfo/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fieldName, value }),
    });

    if (response.ok) {
      setState('integrationInfo', await response.json());
      setSuccessMessages(() => {
        return { ...successMessages, [fieldName]: true };
      });
    } else {
      throw response;
    }
  }

  function hideSuccessMessage(fieldName) {
    setSuccessMessages(() => {
      return { ...successMessages, [fieldName]: false };
    });
  }

  async function clearBroker(e) {
    e.stopPropagation();
    await brokerLinked(null);
  }

  async function brokerLinked(conceptOneEntityId) {
    const response = await fetch(`/bonds/api/applications/${urlParams.get('applicationid')}/applicationintegrationinfo/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fieldName: 'BrokerAgreementConceptOneEntityID', value: conceptOneEntityId }),
    });

    if (response.ok) {
      setState('integrationInfo', await response.json());

      setSuccessMessages(() => {
        return { ...successMessages, brokerAgreementConceptOneEntityID: true };
      });
    } else {
      throw response;
    }
  }

  if (loading)
    return (
      <div className="p-text-center">
        <ProgressSpinner />
      </div>
    );
  if (error) throw error;

  const producers = results.producers.map((d) => {
    return { label: d.name, value: d.identifier };
  });
  const accountManagers = results.accountManagers.map((d) => {
    return { label: d.name, value: d.identifier };
  });
  const legalEntities = results.legalEntities.map((e) => {
    return { label: e.description, value: e.id };
  });

  return (
    <section>
      <div className="p-fluid p-formgrid p-grid" style={{ color: '2em' }}>
        <div className="p-field p-col-12">
          <label htmlFor="producer">Producer</label>
          <SaveNotification show={successMessages.producerConceptOneEntityID ?? false} onHide={() => hideSuccessMessage('producerConceptOneEntityID')} />
          <Dropdown
            id="producerConceptOneEntityID"
            className="sidebar-input"
            options={producers}
            onChange={(e) => changed('producerConceptOneEntityID', e.value)}
            value={results.integrationInfo?.producerConceptOneEntityID}
            showClear={true}
          />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="accountManagerConceptOneEntityID">Account Manager</label>
          <SaveNotification show={successMessages.accountManagerConceptOneEntityID ?? false} onHide={() => hideSuccessMessage('accountManagerConceptOneEntityID')} />
          <Dropdown
            id="accountManagerConceptOneEntityID"
            className="sidebar-input"
            options={accountManagers}
            onChange={(e) => changed('accountManagerConceptOneEntityID', e.value)}
            value={results.integrationInfo?.accountManagerConceptOneEntityID}
            showClear={true}
          />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="legalEntity">Legal Entity</label>
          <SaveNotification show={successMessages.legalentityid ?? false} onHide={() => hideSuccessMessage('legalentityid')} />
          <Dropdown
            id="legalEntity"
            className="sidebar-input"
            options={legalEntities}
            onChange={(e) => changed('legalentityid', e.value)}
            value={results.integrationInfo?.conceptOneLegalEntityID}
            showClear={true}
          />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="broker">Broker</label>
          <SaveNotification show={successMessages.brokerAgreementConceptOneEntityID ?? false} onHide={() => hideSuccessMessage('brokerAgreementConceptOneEntityID')} />
          <div id="broker" className="p-dropdown p-component sidebar-input p-dropdown-clearable" onClick={() => setShowChooseBrokerDialog(true)}>
            <label className="p-dropdown-label p-inputtext">{results.integrationInfo?.brokerConceptOneEntityName}&nbsp;</label>
            {results.integrationInfo?.brokerAgreementConceptOneEntityID && <i className="p-dropdown-clear-icon pi pi-times" onClick={clearBroker}></i>}
            <div className="p-dropdown-trigger">
              <span className="p-dropdown-trigger-icon pi pi-chevron-down p-clickable"></span>
            </div>
          </div>
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="broker">Invoice Terms</label>
          <SaveNotification show={successMessages.invoiceTerms ?? false} onHide={() => hideSuccessMessage('invoiceTerms')} />
          <Dropdown
            id="invoiceTerms"
            className="sidebar-input"
            options={invoiceTermsSelectItems}
            onChange={(e) => changed('invoiceTerms', e.value)}
            value={results.integrationInfo?.invoiceTerms}
            showClear={true}
          />
        </div>
      </div>
      <ChooseDialog showChooseBrokerDialog={showChooseBrokerDialog} onChooseBrokerDialogClosed={() => setShowChooseBrokerDialog(false)} onLinkBroker={brokerLinked} />
    </section>
  );
}
