import React from 'react';
import { render } from 'react-dom';

import Dashboard from './components/dashboard/Dashboard.js';
import ApplicationQuickInfo from './components/application-quick-info/ApplicationQuickInfo.js';
import InfoSideBar from './components/info-sidebar/InfoSidebar.js';
import AchPaymentWarningModal from './components/payment/AchPaymentWarningModal.js';
import Notifications from './components/notifications/Notifications.js';
import FraudWarningModal from './components/fraud/FraudWarningModal.js';
import CancellationModal from './components/cancellation/CancellationModal.js';

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

var renderToIdIfExists = function (element, id) {
  const renderTo = document.getElementById(id);
  if (renderTo) {
    render(<React.StrictMode>{element}</React.StrictMode>, renderTo);
  }
};
renderToIdIfExists(<ApplicationQuickInfo />, 'cra-application-quick-info-app');
renderToIdIfExists(<InfoSideBar />, 'cra-info-sidebar-app');
renderToIdIfExists(<Dashboard />, 'cra-dashboard-app');
renderToIdIfExists(<AchPaymentWarningModal />, 'cra-ach-payment-warning-modal-app');
renderToIdIfExists(<Notifications />, 'cra-notifications-app');
renderToIdIfExists(<CancellationModal />, 'cancellation-button-app');
renderToIdIfExists(<FraudWarningModal />, 'fraud-warning-app');

//   renderToIdIfExists(<Reporting />, 'administration-reporting-app');
//   renderToIdIfExists(<ManageBondNumbers />, 'administration-bond-numbers-app');
//   renderToIdIfExists(<Notifications />, 'notifications-app');
//   renderToIdIfExists(<AuditModal />, 'audit-app');
//   renderToIdIfExists(<Integrations />, 'integrations-app');
//   renderToIdIfExists(<EditCancellationInfo />, 'edit-cancellation-info-app');
//   renderToIdIfExists(<IntegrationsDataEntry />, 'integrations-data-entry-app');
