import { useState, useRef, useEffect, useCallback } from 'react';

export default function useFetchAll(urls) {
  const prevUrls = useRef([]);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Only run if the array of URLs passed in changes
    if (areEqual(prevUrls.current, urls)) {
      setLoading(false);
      return;
    }
    prevUrls.current = urls;

    const promises = Object.keys(urls).map((key) =>
      fetch(urls[key].url).then((response) => {
        if (response.ok) return response.json();

        const validErrorCodes = urls[key].validErrorCodes ?? [];
        if (validErrorCodes.includes(response.status)) return Promise.resolve();

        throw response;
      }),
    );

    Promise.all(promises)
      .then((results) => {
        let result = {};
        const keys = Object.keys(urls);
        for (let index = 0; index < results.length; index++) {
          result[keys[index]] = results[index];
        }
        setResults(result);
      })
      .catch((e) => {
        console.error(e);
        setError(e);
      })
      .finally(() => setLoading(false));
  }, [urls]);

  return {
    results,
    loading,
    error,
    setState: useCallback(
      (key, value) => {
        setResults(() => {
          return { ...results, [key]: value };
        });
      },
      [results],
    ),
  };
}

const areEqual = (item1, item2) => {
  return JSON.stringify(item1) === JSON.stringify(item2);
};
