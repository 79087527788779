import React from 'react';

import IntegrationFailures from './IntegrationFailures.js';
import BondsByStatus from './BondsByStatus.js';

import './Dashboard.scss';

export default function Dashboard() {
  return (
    <>
      <IntegrationFailures />
      <div className="clear"></div>
      <div className="divider"></div>
      <BondsByStatus />
    </>
  );
}
