import React from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import useFetch from '../../services/useFetch';

export default function BondsByStatus() {
  const { data: statuses, loading, error } = useFetch('/bonds/api/bonds/summary/bystatus');

  if (error) throw error;
  if (!statuses) return null;

  var statusTemplate = function (rowData) {
    const href = `/bonds/administrator/Applications.aspx?status=${encodeURI(rowData.status)}`;
    return <a href={href}>{rowData.status}</a>;
  };

  return (
    <div>
      <h2>Bonds By Status</h2>
      <DataTable value={statuses} id="bonds-by-status-table" loading={loading} style={{ width: '75%' }}>
        <Column header="Status" field="status" sortable={true} body={statusTemplate} />
        <Column header="Count" field="count" sortable={true} style={{ width: '150px' }} />
      </DataTable>
    </div>
  );
}
