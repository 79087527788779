import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';

import './ChooseDialog.scss';

ChooseDialog.propTypes = {
  showChooseBrokerDialog: PropTypes.bool,
  onChooseBrokerDialogClosed: PropTypes.func,
  onLinkBroker: PropTypes.func,
};

const stateSelectItems = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

const urlParams = new URLSearchParams(window.location.search);

export default function ChooseDialog({ showChooseBrokerDialog, onChooseBrokerDialogClosed, onLinkBroker }) {
  const [application, setApplication] = useState();
  const [nameFilter, setNameFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState('');
  const [expandedRows, setExpandedRows] = useState();
  const [agreements, setAgreements] = useState([]);

  var search = async function (agency, emailFilter = '', stateFilter = '') {
    if (!agency & !emailFilter && !stateFilter) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);

    const response = await fetch(`/bonds/api/brokers/search?name=${agency}&email=${emailFilter}&state=${stateFilter ?? ''}`);
    if (response.ok) {
      setEmptyMessage('No Brokers Found');
      setSearchResults(await response.json());
      setIsSearching(false);
    } else {
      throw response;
    }
  };

  var onShow = async function () {
    const response = await fetch(`/bonds/api/applications/${urlParams.get('applicationid')}`);
    if (response.ok) {
      const app = await response.json();
      setApplication(app);
      setNameFilter(app.agentOfRecordAgencyName || '');
      setEmailFilter(app.agentOfRecordEmail || '');
      await search(app.agentOfRecordAgencyName, app.agentOfRecordEmail);
    } else {
      throw response;
    }
  };

  var onSearchClicked = async function (e) {
    e.preventDefault();
    await search(nameFilter, emailFilter, stateFilter);
  };

  var onBrokerSelect = function (entityId) {
    onLinkBroker(entityId);
    onChooseBrokerDialogClosed();
  };

  var addressTemplate = function (rowData) {
    return (
      <ul className="address-list">
        {rowData.addresses?.map((address, index) => (
          <li key={index}>
            <address>
              {address.address}
              <br />
              {address.city}, {address.state} {address.postalCode}
            </address>
          </li>
        ))}
      </ul>
    );
  };

  var viewAgreementsTemplate = function (rowData) {
    return <Button label="View Agreements" type="button" onClick={() => viewAgreements(rowData.entityId)} />;
  };

  var selectAgreementsTemplate = function (rowData) {
    return <Button label="Select Agreement" type="button" onClick={() => onBrokerSelect(rowData.conceptOneAgreementID)} />;
  };

  var viewAgreements = async function (entityId) {
    setExpandedRows(null);

    const response = await fetch(`/bonds/api/brokers/${entityId}/agreements`);
    if (response.ok) {
      setAgreements(await response.json());
    } else {
      throw response;
    }

    setExpandedRows({ [entityId]: true });
  };

  var agreementsTemplate = function () {
    return (
      <DataTable value={agreements} emptyMessage="No agreements" dataKey="conceptOneAgreementID">
        <Column field="agreementNumber" header="Agreement" sortable={true} />
        <Column field="effectiveDate" header="Effective" sortable={true} body={(rowData) => dateColumnTemplate(rowData.effectiveDate)} />
        <Column field="expirationDate" header="Expiration" sortable={true} body={(rowData) => dateColumnTemplate(rowData.expirationDate)} />
        <Column style={{ width: '150px' }} body={selectAgreementsTemplate} />
      </DataTable>
    );
  };

  const emailTemplate = (rowData) => {
    return <span title={rowData.emailAddress}>{rowData.emailAddress}</span>;
  };

  var dateColumnTemplate = function (value) {
    if (!value) return '';

    return moment(value).format('l');
  };

  var brokerEmailClicked = function () {
    const recipient = 'Emily.mccormack@vgm.com;lizzie.kelly@vgm.com';
    const subject = 'Broker Appointment - Surety Bond';
    const body =
      'We received a new surety bond application with a broker that is not appointed with VGM Insurance Services, Inc. Please send broker appointment request to the below contact:';
    const agency = `Name of Insurance Agency: ${application.agentOfRecordAgencyName}`;
    const agent = `Agent Name: ${application.agentOfRecord}`;
    const agentEmail = `Agent Email: ${application.agentOfRecordEmail}`;
    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}
                              %0D%0A%0D%0A${encodeURIComponent(agency)}%0D%0A${encodeURIComponent(agent)}%0D%0A${encodeURIComponent(agentEmail)}`;
  };

  const footer = searchResults.length === 0 ? '' : `${searchResults.length} results`;

  return (
    <Dialog
      header="Choose Broker"
      visible={showChooseBrokerDialog}
      modal={true}
      onHide={onChooseBrokerDialogClosed}
      onShow={onShow}
      style={{ width: '50vw' }}
      footer={footer}
      appendTo={document.body}
    >
      <form onSubmit={onSearchClicked}>
        {application?.agentOfRecordAgencyName && (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-4">
              <label>Agent</label>
              <div>{application?.agentOfRecord}</div>
            </div>
            <div className="p-field p-col-4">
              <label>Agency</label>
              <div>{application?.agentOfRecordAgencyName}</div>
            </div>
            <div className="p-field p-col-4">
              <label>Email</label>
              <div>{application?.agentOfRecordEmail}</div>
            </div>
          </div>
        )}

        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-6">
            <label htmlFor="agency">Agency</label>
            <InputText id="agency" type="text" onChange={(e) => setNameFilter(e.target.value)} value={nameFilter} />
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-6">
            <label htmlFor="email">Email</label>
            <InputText id="email" type="text" onChange={(e) => setEmailFilter(e.target.value)} value={emailFilter} />
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-6">
            <label htmlFor="state">State</label>
            <Dropdown id="state" options={stateSelectItems} onChange={(e) => setStateFilter(e.value)} value={stateFilter} showClear={true} />
          </div>
        </div>
        <Button label="Search" disabled={!nameFilter && !emailFilter && !stateFilter} />
      </form>
      <DataTable
        value={searchResults}
        scrollable={true}
        scrollHeight="200px"
        style={{ marginTop: '30px' }}
        loading={isSearching}
        emptyMessage={emptyMessage}
        dataKey="entityId"
        rowExpansionTemplate={agreementsTemplate}
        expandedRows={expandedRows}
      >
        <Column field="name" header="Name" sortable={true} />
        <Column
          field="emailAddress"
          header="Email"
          sortable={true}
          style={{ overflow: 'hidden', maxWidth: '200px', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          body={emailTemplate}
        />
        <Column field="address" header="Address" body={addressTemplate} sortable={true} />
        <Column style={{ width: '150px' }} body={viewAgreementsTemplate} />
      </DataTable>
      <Button label="Create Broker Appointment Email" style={{ marginTop: '15px' }} onClick={brokerEmailClicked} type="button" />
    </Dialog>
  );
}
