import React from 'react';

import { Tree } from 'primereact/tree';

import useFetch from '../../services/useFetch';

export default function IntegrationFailures() {
  const { data: failures, loading, error } = useFetch('/bonds/api/contracts/failures');

  if (error) throw error;
  if (!failures) return null;

  function nodeTemplate(node) {
    if (!node.useTemplate) {
      return node.label;
    }

    return <a href={`/bonds/Administrator/Integrations.aspx?applicationid=${node.applicationID}`}>{node.label}</a>;
  }

  const contractIntegrationFailures = failures.filter((d) => !d.renewalID && d.action === 1);
  const renewalIntegrationFailures = failures.filter((d) => d.renewalID && d.action === 1);
  const cancellationFailures = failures.filter((d) => d.action === 2);

  const nodes = [
    {
      key: 'contract-parent-1',
      label: `Contract Integrations (${contractIntegrationFailures.length})`,
      useTemplate: false,
      children: contractIntegrationFailures.map((d) => {
        return {
          key: `bond${d.bondID}`,
          label: `${d.bondNumber}: ${d.companyName}`,
          useTemplate: true,
          applicationID: d.applicationID,
        };
      }),
    },
    {
      key: 'renewal-parent-1',
      label: `Renewal Integrations (${renewalIntegrationFailures.length})`,
      useTemplate: false,
      children: renewalIntegrationFailures.map((d) => {
        return {
          key: `bond${d.bondID}renewal${d.renewalID}`,
          label: `${d.bondNumber}: ${d.companyName}`,
          useTemplate: true,
          applicationID: d.applicationID,
        };
      }),
    },
    {
      key: 'cancellation-parent-1',
      label: `Cancellation Integrations (${cancellationFailures.length})`,
      useTemplate: false,
      children: cancellationFailures.map((d) => {
        return {
          key: `cancellation${d.bondID}`,
          label: `${d.bondNumber}: ${d.companyName}`,
          useTemplate: true,
          applicationID: d.applicationID,
        };
      }),
    },
  ];

  return (
    <div id="integration-failures">
      <h2>ConceptOne Integration Failures</h2>
      <Tree value={nodes} loading={loading} nodeTemplate={nodeTemplate} />
    </div>
  );
}
