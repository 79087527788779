import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';


export default function CancellationModal() {

    let appID = '';
    let companyID = '';

    const [showModal, setShowModal] = useState(false);

    async function IsContractBinded(){
        companyID = await document.getElementById("companyID").value
        appID = await document.getElementById("appID").value
        const response = await fetch(`/bonds/api/applications/${appID}/consumer`);

        if(response.status != 200)
        {
            setShowModal(true);
        }
        else {
            window.location.href= `/bonds/Administrator/location-removal.aspx?id=${companyID}&applicationid=${appID}`;
        }
    };

    function CancelAnyway() {
        setShowModal(false);
        window.location.href= `/bonds/Administrator/location-removal.aspx?id=${companyID}&applicationid=${appID}`;
    }

    return (
      <>
    
        <p><a pButton style={{cursor: 'pointer'}} onClick={() => IsContractBinded()}>Cancel</a></p>
        
        <Dialog header="Cancellation" visible={showModal} style={{ width: '30vw' }} onHide={() => setShowModal(false)} modal={true} resizable={false} draggable={false} closeOnEscape={true} closable={true}>
        <p>This bond is not linked to a consumer.</p>
        <Button onClick={() => CancelAnyway()} icon="pi" label="Cancel Anyway" />
        <Button onClick={() => setShowModal(false)} icon="pi" style={{ float: 'right' }} iconPos="right" label="Okay" />
        
      </Dialog>
      </>
    );
}
  